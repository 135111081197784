import React from "react"
import { BookmarksPage } from "../components/BookmarksPage/BookmarksPage"
import { Seo } from "../components/Seo/Seo"
import { buildFaq } from "../utils/buildFaq"

const SearchTemplate = props => {
  return (
    <>
      <Seo
        title={props.pageContext.meta?.title}
        description={props.pageContext.meta?.description}
        indexable={props.pageContext.meta?.indexable}
        domain={props.pageContext.configuration.domain}
        image={props.pageContext.meta?.image?.src?.publicURL}
        lang={props.pageContext.language}
        faq={buildFaq(props.pageContext.page.content)}
      />

      <BookmarksPage
        page={props.pageContext.page}
        articles={props.pageContext.articles}
        categories={props.pageContext.categories}
        lang={props.pageContext.language}
        theme={props.pageContext.theme}
        header={props.pageContext.header}
        aside={props.pageContext.aside}
        footer={props.pageContext.footer}
        drawer={props.pageContext.drawer}
        language={props.pageContext.language}
        cookies={props.pageContext.cookies}
        authors={props.pageContext.authors}
      />
    </>
  )
}

export default SearchTemplate
